import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { FC } from "react";
import A4 from "@layouts/A4";
import About from "@sections/About";
import Contact from "@sections/Contact";
import Education from "@sections/Education";
import Experiences from "@sections/Experiences";
import Header from "@sections/Header";
import Hobbies from "@sections/Hobbies";
import Information from "@sections/Information";
import Interests from "@sections/Interests";
import Languages from "@sections/Languages";
import Skills from "@sections/Skills";
import Tools from "@sections/Tools";

const Index: FC = () => {
  return (
    <A4>
      <header className="pb-4">
        <Header job="Cybersecurity student">
          <StaticImage
            src="../../../images/linkedin.png"
            alt=""
            className="h-24 w-24 rounded-full border-2 border-white shadow brightness-110"
          />
        </Header>
      </header>

      <main className="flex flex-row">
        <div className="mr-4 flex basis-3/4 flex-col gap-4 rounded-lg bg-white p-4">
          <About sumary="I'm a passionate cybersecurity student who loves to learn and understand how things work. I'm interested in a lot of things, but committed to build tools to improve everyone's life. Technology can be harmful, that is why I pay attention to contribute in the best way possible through ethical hacking and open source." />
          <Experiences
            experiences={[
              {
                title: "isTrust",
                employer:
                  "||Internet Society Chapter Belgium vzw/asbl|https://www.internetsociety.be/||",
                job: "Full-Stack Web Developer",
                period: {
                  from: new Date(2021, 7),
                },
                tasks: [
                  "Developed cross-platform browser extension using :Vue:, :Typescript: and :Tailwindcss:",
                  "Deployed serverless api developed in :Typescript: to handle high loads < 200ms",
                  "Designed website using :Nuxt:",
                  "Simplified concepts behind the extension for non-technical users", // vulgarisateur
                ],
                links: [
                  new URL("https://www.istrust.org/"),
                  new URL(
                    "https://github.com/Internet-Society-Belgium/isTrust"
                  ),
                ],
              },
              // {
              //   title: "Etoosi Music",
              //   job: "Full-Stack Software Engineer",
              //   period: {
              //     from: new Date(2018, 6),
              //   },
              //   tasks: [
              //     "Developed cross-platform application with :Electron: and :Typescript:",
              //     "Designed front-end using :Vue: and :Tailwindcss:",
              //   ],
              //   links: [new URL("https://etoo.si/music/")],
              // },
              {
                title: "L-Type",
                job: "Full-Stack Software Engineer",
                period: {
                  from: new Date(2020, 9),
                  to: new Date(2021, 3),
                },
                tasks: [
                  "Engineered :C++: communication architecture to get a very low latency experience",
                  "Converted into a :Docker: container to be playable through the browser",
                  "Developed shoppable theme system to maximize owner's profit", // business: economic aspect
                ],
                links: [new URL("https://github.com/etoome/L-Type")],
              },
              {
                title: "Covided",
                job: "Full-Stack Web Developer",
                period: {
                  from: new Date(2021, 2),
                  to: new Date(2021, 4),
                },
                tasks: [
                  "Developed front-end dashboard using :Next:, :Typescript: and styled with :Tailwindcss:",
                  "Handelled api requests with :Express: and getting data from :Postgresql:",
                  "Deployed project with :Docker: and preprocessing data with :Python:",
                ],
                links: [new URL("https://github.com/etoome/Covided")],
              },
              // {
              //   title: "Universal Drum School",
              //   job: "Webmaster",
              //   period: {
              //     from: new Date(2020, 9),
              //     to: new Date(2020, 10),
              //   },
              //   tasks: [
              //     "Configured and customized :Wordpress: for digital asset selling and bloging",
              //     "Designed brand web identity with :Elementor:",
              //   ],
              //   links: [new URL("https://universaldrumschool.com/")],
              // },
              // {
              //   title: "Slideways",
              //   job: "Full-Stack Software Engineer",
              //   period: {
              //     from: new Date(2019, 9),
              //     to: new Date(2020, 3),
              //   },
              //   technologies: ["Python"],
              //   links: [new URL("https://github.com/etoome/Slideways")],
              // },
              {
                title: "CV",
                job: "Frontend Web Developer",
                period: {
                  from: new Date(2022, 3),
                  to: new Date(2022, 5),
                },
                technologies: ["Gatsby", "Tailwindcss"],
                links: [new URL("https://etoo.me/")],
              },
              {
                title: "Autochef",
                job: "Full-Stack Software Engineer",
                period: {
                  from: new Date(2021, 9),
                  to: new Date(2022, 3),
                },
                tasks: [
                  "Designed and implemented all UX/UI for easy and intuitive usage",
                  "Developed backend for all shopping list related features",
                  "Setup CI/CD to achieve replicable dev environement and automatic builds",
                ],
                links: [new URL("https://github.com/etoome/AutoChef")],
              },
              {
                title: "MakeMeWeb",
                job: "Full-Stack Web Developer",
                period: {
                  from: new Date(2022, 7),
                  to: new Date(2022, 8),
                },
                tasks: [
                  "Hacked :Chrome: into web page PDF generator with perfect fidelity",
                  "Developed :git: web interface including visual JSON conflicts resolutions",
                ],
                links: [new URL("https://www.makemeweb.net/")],
              },
              {
                title: "NoHackGenda",
                job: "Full-Stack Web Developer",
                period: {
                  from: new Date(2022, 11),
                  to: new Date(2023, 0),
                },
                tasks: [
                  "Designed a security focus online calendar with zero trust principle",
                  "Achieved the best security compromise using PGP, :Svelte: SSG and :Express:",
                ],
                links: [new URL("https://github.com/etoome/NoHackGenda")],
              },
              {
                title: "Rainbow Table",
                job: "Software Engineer",
                period: {
                  from: new Date(2022, 10),
                  to: new Date(2022, 10),
                },
                technologies: ["C++"],
                links: [new URL("https://github.com/etoome/Rainbow-Table")],
              },
            ]}
          />
          <div className="hidden print:block">
            <div className="h-28"></div>
          </div>
          <Education />
          <Hobbies
            hobbies={[
              {
                title: "Scouts",
                items: [
                  {
                    title: "Animator - Baladins (boys 6-7)",
                    period: {
                      from: new Date(2017, 8),
                      to: new Date(2019, 6),
                    },
                  },
                  {
                    title: "Group leader - Baladins (boys 6-7)",
                    period: {
                      from: new Date(2019, 8),
                      to: new Date(2021, 6),
                    },
                  },
                  {
                    title: "Treasurer - Lutins (girls 8-11)",
                    period: {
                      from: new Date(2021, 8),
                      to: new Date(2022, 6),
                    },
                  },
                  {
                    title: "Treasurer - Horizons (girls 15-16)",
                    period: {
                      from: new Date(2022, 8),
                    },
                  },
                  {
                    title: "Animator - Lutins (girls 8-11)",
                    period: {
                      from: new Date(2022, 8),
                    },
                  },
                ],
              },
              {
                title: "Volunteering",
                items: [
                  {
                    title:
                      "||Internet Society Chapter Belgium vzw/asbl|https://www.internetsociety.be/||",
                    period: {
                      from: new Date("2019"),
                    },
                    periodFormat: "shorter",
                  },
                ],
              },
            ]}
          />
        </div>
        <div className="ml-2 flex basis-1/4 flex-col gap-4">
          <Information />
          <Contact
            links={[
              new URL("https://github.com/etoome"),
              new URL("https://linkedin.com/in/julientaes/"),
              // new URL("https://play.picoctf.org/users/etoome"),
              // new URL("https://tryhackme.com/p/etoome"),
              // new URL("https://app.hackthebox.com/profile/918270"),
              // new URL("https://www.root-me.org/etoome"),
              // new URL("https://etoo.me/"),
            ]}
          />
          <Languages />
          <Skills
            skills={[
              "perfectionism",
              "creativity",
              "curiosity",
              "honesty",
              "research",
              "problem detection and solving",
              "out of the box thinking",
            ]}
          />
          <Interests
            interests={[
              "influence",
              "psychology",
              "sociology",
              "marketing",
              "political manners",
              "philosophy",
              "entrepreneurship",
              "movies",
              "investment",
              "video editing",
              "3D modeling",
              "VFX",
              "DIY",
            ]}
          />
          <Tools tools={["Linux", "Git", "VSCodium", "Kali"]} />
        </div>
      </main>
    </A4>
  );
};

export default Index;
